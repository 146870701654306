import { createTheme, getContrastRatio } from '@mui/material/styles';

import colors from './colors';
import typography from './typography';

const _getContrastText = (color) =>
  getContrastRatio(color, colors.common.white) > 3 ? colors.common.white : colors.common.black;

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 1024,
    },
  },
  typography: {
    fontFamily: 'Toyota Type',
    display1: {
      fontSize: '2rem', // 32px
      fontWeight: 600,
      lineHeight: '2.5rem', // 40px
    },
    display2: {
      fontSize: '2rem', // 32px
      fontWeight: 400,
      lineHeight: '2.5rem', // 40px
    },
    display3: {
      fontFamily: 'Toyota Type Book',
      fontSize: '2rem', // 32px
      fontWeight: 400,
      lineHeight: '2.5rem', // 40px
    },
    h1: {
      fontSize: '1.5rem', // 24px
      fontWeight: 600,
      lineHeight: '2rem', // 32px
    },
    h2: {
      fontSize: '1.5rem', // 24px
      fontWeight: 400,
      lineHeight: '2rem', // 32px
    },
    h3: {
      fontSize: '1.25rem', // 20px
      fontWeight: 600,
      lineHeight: '1.75rem', // 28px
    },
    h4: {
      fontSize: '1.25rem', // 20px
      fontWeight: 400,
      lineHeight: '1.75rem', // 28px
    },
    h5: {
      fontSize: '1rem', // 16px
      fontWeight: 600,
      lineHeight: '1.375rem', // 22px
    },
    h6: {
      fontSize: '1rem', // 16px
      fontWeight: 400,
      lineHeight: '1.375rem', // 22px
    },
    body1: {
      fontSize: '0.875rem', // 14px
      fontWeight: 600,
      lineHeight: '1rem', // 16px
    },
    body2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 400,
      lineHeight: '1rem', // 16px
    },
    body3: {
      fontSize: '0.875rem', // 14px
      fontWeight: 600,
      lineHeight: '1.25rem', // 20px
    },
    body4: {
      fontSize: '0.875rem', // 14px
      fontWeight: 400,
      lineHeight: '1.25rem', // 20px
    },
    caption1: {
      fontSize: '0.75rem', // 12px
      fontWeight: 600,
      lineHeight: '1.125rem', // 18px
    },
    caption2: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
      lineHeight: '1.125rem', // 18px
    },
    label1: {
      fontSize: '0.75rem', // 12px
      fontWeight: 600,
      lineHeight: '1rem', // 16px
    },
    label2: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
      lineHeight: '1rem', // 16px
    },
    print1: {
      fontSize: '0.5625rem', // 9px
      fontWeight: 600,
    },
    print2: {
      fontSize: '0.5625rem', // 9px
      fontWeight: 400,
    },
  },
  palette: {
    divider: colors.secondary[80],
    common: { ...colors.common },
    background: {
      default: colors.secondary[100],
      dark: colors.common.black,
      surfacePaper: colors.common.white,
      surfaceLight: colors.secondary[90],
      surfaceDark: colors.secondary[20],
      surfaceDarkest: colors.secondary[10],
    },
    text: {
      primary: colors.common.black,
      secondary: colors.secondary[30],
      disabled: colors.secondary[50],
      white: colors.common.white,
      link: colors.info[50],
      error: colors.error[50],
    },
    primary: {
      main: colors.common.black,
      light: colors.secondary[90],
      dark: colors.secondary[20],
      contrastText: _getContrastText(colors.common.black),
    },
    secondary: {
      main: colors.secondary[10],
      light: colors.secondary[90],
      dark: colors.secondary[20],
      contrastText: _getContrastText(colors.secondary[10]),
      ...colors.secondary,
    },
    success: {
      main: colors.success[50],
      light: colors.success[80],
      dark: colors.success[30],
      contrastText: _getContrastText(colors.success[50]),
      ...colors.success,
    },
    warning: {
      main: colors.warning[50],
      light: colors.warning[80],
      dark: colors.warning[30],
      contrastText: _getContrastText(colors.warning[50]),
      ...colors.warning,
    },
    info: {
      main: colors.info[50],
      light: colors.info[100],
      dark: colors.info[20],
      contrastText: _getContrastText(colors.info[50]),
      ...colors.info,
    },
    error: {
      main: colors.error[50],
      light: colors.error[80],
      dark: colors.error[30],
      contrastText: _getContrastText(colors.error[50]),
      ...colors.error,
    },
    purple: { ...colors.purple },
    teal: { ...colors.teal },
    yellow: { ...colors.yellow },
    cyan: { ...colors.cyan },
    magenta: { ...colors.magenta },
    brand: {
      toyotaRed: '#eb0a1e', // NOT IN FULL TONAL PALETTE
      toyotaGray: '#58595b', // secondary30
      lexusGold: '#b6a171', // NOT IN FULL TONAL PALETTE
      lexusGray: '#adadad', // NOT IN FULL TONAL PALETTE
    },
    data: {
      darkTeal: '#004646', // NOT IN FULL TONAL PALETTE
      lightTeal: '#54aaaa', // NOT IN FULL TONAL PALETTE
      orange: '#ff832b', // NOT IN FULL TONAL PALETTE
      darkRed: '#8b0611', // NOT IN FULL TONAL PALETTE
      errorContainer: '#feebed', // NOT IN FULL TONAL PALETTE
      onErrorContainer: '#76050e', // NOT IN FULL TONAL PALETTE
    },
    etaStatus: {
      ...colors.statusColors,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `${typography}
      html {scroll-behavior: smooth;}`,
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: '2rem',
          paddingRight: '2rem',
          [theme.breakpoints.down('tablet')]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
        }),
        disableGutters: {
          padding: 0,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
        },
        outlined: ({ ownerState, theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette[ownerState.color].dark,
            borderColor: theme.palette[ownerState.color].dark,
            color: _getContrastText(theme.palette[ownerState.color].dark),
          },
        }),
        text: ({ ownerState, theme }) => ({
          '&[aria-expanded="true"], &[aria-pressed="true"]': {
            backgroundColor: theme.palette.secondary[80],
          },
          '&:hover': {
            backgroundColor: theme.palette[ownerState.color].light,
            color: _getContrastText(theme.palette[ownerState.color].light),
          },
        }),
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const styles = {
            borderRadius: '0.25rem',
            '& .MuiTouchRipple-root .MuiTouchRipple-child': {
              borderRadius: '0.25rem',
            },
          };

          if (ownerState.color !== 'inherit') {
            styles['&:hover'] = {
              backgroundColor: theme.palette[ownerState.color].light,
            };
          }

          return styles;
        },
      },
      defaultProps: {
        centerRipple: false,
        color: 'primary',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          padding: '0.25rem 0.5rem',
          height: '1.5rem',
        },
        label: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1rem',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '6.5rem',
          zIndex: 1100,
        },
      },
    },
  },
});

export default theme;
