import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

const DATE_FORMAT = 'MM/DD/YYYY';

export const VEHICLE_CONDITION_MAP = {
  series: 'Series',
  modelYear: 'Model Year',
  modelCode: 'Model Code',
};

export const EVENT_CONDITION_MAP = {
  shipToRegion: 'Ship to Region',
  soldToRegion: 'Sold to Region',
  assignmentOrigin: 'Assignment Origin',
  assignmentDestination: 'Assignment Destination',
  legOrigin: 'Leg Origin',
  legDestination: 'Leg Destination',
  triggerEventCode: 'Trigger Event Code',
  predictedEventCode: 'Predicted Event Code',
  buildDaysDiff: 'Build Days Difference',
  fqaDaysDiff: 'FQA Days Difference',
  urnModelYear: 'URN Model Year',
};

export const COND_TITLE_MAP = {
  event: 'Event Conditions',
  vehicle: 'Vehicle Conditions',
};

export const rulesetHistoryHeaders = [
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'teamMember',
    label: 'Team Member',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'division',
    label: 'Division',
  },
];

const _countConditions = (eventConditions, vehicleConditions) => {
  const eventCount = Object.values(eventConditions).reduce((acc, value) => {
    if (value && value.length && value[0]) {
      return acc + 1;
    }

    return acc;
  }, 0);

  return eventCount + (vehicleConditions?.length || 0);
};

const _getDateDiff = (startDate, endDate, unit) => endDate.diff(startDate, unit) || 0;

const _formatDate = (date, format, fallback) => {
  const parsedDate = dayjs(date);
  return parsedDate.isValid() ? parsedDate.format(format) : fallback || '';
};

const _getStatus = (startDate, endDate, suppressionFlag, deleted) => {
  if (deleted) {
    return 'deleted';
  }

  const currentDate = dayjs();

  if (currentDate.isBetween(startDate, endDate)) {
    if (suppressionFlag) {
      return 'active';
    } else {
      return 'paused';
    }
  }

  if (currentDate.isBefore(startDate)) {
    return 'activating';
  }

  return '';
};

const _getEventConditions = (conditions) => {
  const result = [];

  Object.entries(conditions)
    .filter(([, value]) => value && value[0])
    .forEach(([key, value]) => result.push([{ field: key, label: EVENT_CONDITION_MAP[key], value: value.join(', ') }]));

  return result;
};

const _getVehicleConditions = (conditions) =>
  conditions?.map((condition) => {
    const result = [];

    Object.entries(condition).forEach(([key, value]) =>
      result.push({
        field: key,
        label: VEHICLE_CONDITION_MAP[key],
        value: typeof value === 'string' ? value : value.join(', '),
      }),
    );

    return result;
  }) || [];

const _isSmGlobalFiltersApplied = (filters) => {
  for (const field in filters) {
    if (filters[field]?.length && filters[field][0] !== '__all__') {
      return true;
    }
  }
  return false;
};

const _searchRulesets = (data, searchQuery = '') =>
  searchQuery ? data.filter((rule) => rule.reason.toLowerCase().includes(searchQuery.toLowerCase())) : data;

const _valueMatchFilter = (value, filterValue) =>
  typeof filterValue === 'string' ? value === filterValue : filterValue?.includes(value);

export const formatRulesets = (rulesets) =>
  rulesets
    .filter((ruleset) => ruleset)
    .map((ruleset, index) => ({
      originalData: {
        ...ruleset,
      },
      rulesetId: ruleset.rulesetId || index, // TODO: need BE to return rulesetId
      reason: ruleset.reason,
      deleted: ruleset.deletionFlag || false,
      startDate: _formatDate(ruleset.startDate, DATE_FORMAT, 'N/A'),
      endDate: _formatDate(ruleset.expiryDate, DATE_FORMAT, 'N/A'),
      createdDate: _formatDate(ruleset.createdDate, DATE_FORMAT, ''),
      deletedDate: _formatDate(ruleset.deletionDate, DATE_FORMAT, ''),
      requestor: ruleset.requestor || 'N/A',
      status: _getStatus(ruleset.startDate, ruleset.expiryDate, ruleset.suppressionFlag, ruleset.deletionFlag),
      statusDate: _formatDate(ruleset.deletionFlag ? ruleset.deletionDate : ruleset.startDate, DATE_FORMAT, ''),
      newRule:
        ruleset.createdDate && _getDateDiff(dayjs.utc(ruleset.createdDate), dayjs.utc(), 'hour') < 36 ? true : false,
      details: {
        activeImpact: ruleset.activeImpact || null,
        suppressionOrder: ruleset.suppressionOrder,
        conditionCount: _countConditions(ruleset.eventConditions, ruleset.vehicleConditions),
      },
      eventConditions: _getEventConditions(ruleset.eventConditions),
      vehicleConditions: _getVehicleConditions(ruleset.vehicleConditions),
      dataForFiltering: { assignmentOrigin: ruleset.eventConditions?.assignmentOrigin?.[0] || null },
    }));

export const getRulesetFilterValues = (formattedRuleset, field) =>
  field === 'assignmentOrigin' ? formattedRuleset.dataForFiltering.assignmentOrigin : formattedRuleset?.[field] || null;

export const filterRulesets = (data, filters = null, searchQuery = '') => {
  if (!filters || !_isSmGlobalFiltersApplied(filters)) {
    return _searchRulesets(data, searchQuery);
  }

  const filterFields = Object.keys(filters);

  return _searchRulesets(data, searchQuery).filter((item) => {
    let isMatch = true;

    filterFields.forEach((field) => {
      if (filters[field]?.length && filters?.[field][0] !== '__all__') {
        isMatch = !isMatch ? isMatch : _valueMatchFilter(getRulesetFilterValues(item, field), filters[field]);
      }
    });

    return isMatch;
  });
};

export const getInitialRuleset = (ruleset) => ({
  rulesetId: ruleset.rulesetId,
  reason: ruleset.reason,
  suppressionOrder: ruleset?.details?.suppressionOrder,
  startDate: ruleset.startDate,
  endDate: ruleset.endDate,
  exportFlag: ruleset?.exportFlag,
  suppressionFlag: ruleset?.suppressionFlag,
  eventConditions: ruleset.eventConditions.map((eventCondition) => {
    const condition = eventCondition[0];

    return {
      condition: condition.field,
      values: condition.value.split(', '),
      label: condition.label,
    };
  }),
  vehicleConditions: ruleset.vehicleConditions.map((vehicleCondition) => {
    const result = {};

    vehicleCondition.forEach((condition) => {
      // TODO: refactor once aligned structure with BE is discussed
      if (condition.field === 'series') {
        result.salesSeriesName = condition.value;
      } else {
        result[condition.field] = condition.value.split(', ').filter((val) => !!val); // Filter to avoid [''] as placeholder won't show
      }
    });

    return result;
  }),
});

export const formatRulesetHistory = (data) => {
  if (!data) {
    return [];
  }

  return data?.map((item) => ({
    id: `${item.rulesetId}-${item.version}`,
    version: item.version,
    attributes: [
      { id: 'createdDate', value: item.createdDate },
      { id: 'requestor', value: item.requestor },
      { id: 'userId', value: item.userId },
      { id: 'division', value: item.division },
    ],
    eventConditions: _getEventConditions(item.eventConditions),
    vehicleConditions: _getVehicleConditions(item.vehicleConditions),
  }));
};
