import styled from '@emotion/styled';
import { Alert as MuiAlert } from '@mui/material';

export const Alert = styled(MuiAlert)`
  color: ${({ theme }) => theme.palette.common.black};
  background-color: ${({ theme, severity }) => theme.palette[severity][100]};
  border-left: 0.25rem solid;
  border-color: ${({ theme, severity }) => theme.palette[severity].main};
  max-width: ${({ inline, fullwidth }) => (inline || fullwidth ? '100%' : '22rem')};
  padding: ${({ inline }) => (inline ? '0.5rem' : '1rem')};

  & .MuiAlert-icon {
    padding: 0;
    align-items: ${({ timestamp, subtitle, actiontitle, inline }) =>
      !timestamp && !subtitle && (!actiontitle || inline) ? 'center' : 'start'};
    margin-right: ${({ inline }) => (inline ? '0.625rem' : '1rem')};
  }

  & .MuiAlert-message {
    display: flex;
    width: 100%;
    overflow: visible;
    padding: 0;
    align-items: ${({ timestamp, subtitle, actiontitle, inline }) =>
      !timestamp && !subtitle && (!actiontitle || inline) ? 'center' : 'start'};
  }

  & .MuiAlert-action {
    align-items: ${({ timestamp, subtitle, actiontitle, inline }) =>
      (!timestamp && !subtitle && !actiontitle) || inline ? 'center' : 'start'};
    padding: 0;
    padding-left: ${({ inline }) => (inline ? '2rem' : '1rem')};
    margin-top: ${({ timestamp, subtitle, actiontitle, inline }) =>
      (!timestamp && !subtitle && !actiontitle) || inline ? '0' : '-0.25rem'};
    margin-right: -0.25rem;
  }
`;
