import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO } from '../queries/GetUserInfo';
import { FeatureFlags } from './Features.context';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

export const Roles = createContext({});

const RolesProvider = ({ children }) => {
  const { features } = useContext(FeatureFlags);
  const { data, loading } = useQuery(GET_USER_INFO);

  const userInfo = data?.getUserInfo;

  const permissionsExist = (permissionNames) => {
    if (features?.rolesPermissionsEnabled === false) {
      return true;
    }

    if (!userInfo?.permissions?.[0]?.permissions?.length) {
      return false;
    }

    return (
      userInfo.permissions[0].permissions.some((permission) => permissionNames.includes(permission.toLowerCase())) ||
      false
    );
  };

  const rolesExist = (roleNames) => {
    if (!features?.rolesPermissionsEnabled) {
      return true;
    }

    if (!userInfo?.roles) {
      return false;
    }
    return userInfo?.roles.some((role) => roleNames.includes(role)) || false;
  };

  const contextValue = useMemo(
    () => ({
      permissionsExist,
      rolesExist,
      userId: userInfo?.userId,
      preferredName: userInfo?.preferredName,
      distributorCodes: userInfo?.distributorCodes,
      region: userInfo?.region,
    }),
    [permissionsExist, rolesExist, userInfo],
  );

  if (loading) {
    return <LoadingSpinner fullScreen />;
  }

  return <Roles.Provider value={contextValue}>{children}</Roles.Provider>;
};

RolesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RolesProvider;

export const useRolesContext = () => useContext(Roles);
