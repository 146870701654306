import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider, ApolloClientProvider } from 'cubedist-cube-pipeline-common-ui';
import ArrivalProvider from 'contexts/Arrival.context';
import FeaturesProvider from 'contexts/Features.context';
import NotificationProvider from 'contexts/Notification.context';
import RolesProvider from 'contexts/Roles.context';
import { getEnv } from './utils/environment';
import Router from './Router';
import theme from './theme';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const env = getEnv();

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  datadogRum.init({
    applicationId: '46f18288-cfb2-4c44-8241-0f1f4c1a23e4',
    clientToken: 'pub4b247c5549d01ca959a30da317eb10c0',
    site: 'datadoghq.com',
    service: 'cube-arrival-ui',
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });

  datadogRum.setGlobalContextProperty('account_id', '091975348311');
  datadogRum.startSessionReplayRecording();
}

const queryClient = new QueryClient();

root.render(
  <AuthProvider path="cube-pathname" search="">
    <ApolloClientProvider defaultClient="arrival">
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <FeaturesProvider>
            <RolesProvider>
              <NotificationProvider>
                <ArrivalProvider>
                  <Router />
                </ArrivalProvider>
              </NotificationProvider>
            </RolesProvider>
          </FeaturesProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ApolloClientProvider>
  </AuthProvider>,
);
