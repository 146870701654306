import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const NotRemovedIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 24" width="32" height="24" xmlSpace="preserve">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      transform="scale(2, 2) translate(1.7, -1)"
      d="M1.41818 13.5C0.590909 13.5 0 12.9091 0 12.0818V1.91818C0 1.09091 0.590909 0.5 1.41818 0.5H11.5818C12.4091 0.5 13 1.09091 13 1.91818V12.0818C13 12.9091 12.4091 13.5 11.5818 13.5H1.41818ZM3.30909 9.71818L2.24545 8.65454L5.43636 5.46364L6.85454 6.88182L9.45455 4.28182L10.5182 5.34545L6.85454 9.00909L5.55455 7.59091L3.30909 9.71818Z"
    />
  </svg>,
  'NotRemoved',
);

export default NotRemovedIcon;
