import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NetworkStatus, useQuery } from '@apollo/client';
import { GET_SUPPRESSION_RULES } from 'queries/SuppressionManager';
import { smUtils } from 'utils';

const TABS = ['Active', 'Archived'];

export const SmContext = createContext({});

const SuppressionManagerProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [globalFilterData, setGlobalFilterData] = useState({
    count: 0,
    data: [],
    loading: true,
    reloading: false,
    error: false,
  });
  const [rulesetData, setRulesetData] = useState({
    count: 0,
    data: [],
    loading: true,
    reloading: false,
    error: false,
  });
  const [archivedRulesetData, setArchivedRulesetData] = useState({
    count: 0,
    data: [],
    loading: true,
    reloading: false,
    error: false,
  });

  const { data, loading, error, networkStatus } = useQuery(GET_SUPPRESSION_RULES, {
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: archiveData,
    loading: archiveLoading,
    error: archiveError,
    networkStatus: archiveNeworkStatus,
  } = useQuery(GET_SUPPRESSION_RULES, {
    notifyOnNetworkStatusChage: true,
    variables: {
      deletionFlag: true,
    },
  });

  useEffect(() => {
    setRulesetData((prev) => ({
      count: data?.GetSuppressionRules?.count ?? 0,
      data: data ? smUtils.formatRulesets(data?.GetSuppressionRules?.rulesets || []) : prev.data,
      loading: loading && networkStatus === NetworkStatus.loading,
      reloading: loading,
      error: error,
    }));
  }, [data, loading, error, networkStatus]);

  useEffect(() => {
    setArchivedRulesetData((prev) => ({
      count: archiveData?.GetSuppressionRules?.count ?? 0,
      data: archiveData ? smUtils.formatRulesets(archiveData.GetSuppressionRules?.rulesets || []) : prev.data,
      loading: archiveLoading && archiveNeworkStatus === NetworkStatus.loading,
      reloading: archiveLoading,
      error: archiveError,
    }));
  }, [archiveData, archiveLoading, error, archiveNeworkStatus]);

  useEffect(() => {
    if (selectedTab === 0) {
      setGlobalFilterData(rulesetData);
    }

    if (selectedTab === 1) {
      setGlobalFilterData(archivedRulesetData);
    }
  }, [rulesetData, archivedRulesetData]);

  const handleTabChange = useCallback(
    (tab) => {
      if (tab === 0) {
        setGlobalFilterData(rulesetData);
      }

      if (tab === 1) {
        setGlobalFilterData(archivedRulesetData);
      }

      setSelectedTab(tab);
    },
    [rulesetData, archivedRulesetData],
  );

  const contextValue = useMemo(
    () => ({
      tabs: TABS,
      selectedTab,
      setSelectedTab: handleTabChange,
      globalFilterData,
      rulesetData,
      archivedRulesetData,
    }),
    [rulesetData, archivedRulesetData, globalFilterData, selectedTab],
  );
  return <SmContext.Provider value={contextValue}>{children}</SmContext.Provider>;
};

SuppressionManagerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SuppressionManagerProvider;

export const useSmContext = () => useContext(SmContext);
