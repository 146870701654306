import React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  TimerOutlined as TimerOutlinedIcon,
  WarningAmber as WarningAmberIcon,
  BeenhereOutlined as BeenhereOutlinedIcon,
} from '@mui/icons-material';
import theme from '../../theme';

export const countTypes = ['onSchedule', 'atRisk', 'early', 'delayed', 'etaUnavailable', 'delivered'];

const statusMap = {
  countTypes,
  propMap: {
    onSchedule: {
      chartColor: theme.palette.data.lightTeal,
      statusChipColor: 'success.50',
      backgroundColor: 'success.100',
      heroGradient: theme.palette.etaStatus.onSchedule.gradient,
      color: 'success.10',
      iconBoxColor: theme.palette.data.lightTeal,
      iconId: 'CheckIcon',
      label: 'On Schedule',
      type: 'success',
      variant: 'filled',
    },
    atRisk: {
      chartColor: theme.palette.warning.main,
      statusChipColor: 'warning.50',
      backgroundColor: 'warning.100',
      heroGradient: theme.palette.etaStatus.atRisk.gradient,
      color: 'warning.10',
      iconBoxColor: theme.palette.data.orange,
      iconId: 'WarningAmberIcon',
      label: 'At Risk',
      type: 'warning',
      variant: 'filled',
    },
    early: {
      chartColor: theme.palette.purple[70],
      statusChipColor: 'purple.50',
      backgroundColor: 'purple.100',
      heroGradient: theme.palette.etaStatus.early.gradient,
      color: 'purple.10',
      iconBoxColor: 'purple.70',
      iconId: 'TimerOutlinedIcon',
      label: 'Early',
      type: 'early',
      variant: 'filled',
    },
    delayed: {
      chartColor: theme.palette.error[50],
      statusChipColor: 'error.50',
      backgroundColor: theme.palette.data.errorContainer,
      heroGradient: theme.palette.etaStatus.delayed.gradient,
      color: theme.palette.data.onErrorContainer,
      iconBoxColor: 'error.60',
      iconId: 'CloseIcon',
      label: 'Delayed',
      type: 'error',
      variant: 'filled',
    },
    etaUnavailable: {
      chartColor: theme.palette.secondary[50],
      statusChipColor: theme.palette.common.white,
      backgroundColor: theme.palette.background.surfacePaper,
      heroGradient: theme.palette.etaStatus.etaUnavailable.gradient,
      color: theme.palette.common.black,
      iconBoxColor: theme.palette.secondary[50],
      iconId: 'RemoveCircleOutlineIcon',
      label: 'ETA Unavailable',
      type: 'etaUnavailable',
      variant: 'outlined',
    },
    // update styles as needed - jason kim
    delivered: {
      chartColor: theme.palette.secondary[50],
      statusChipColor: theme.palette.info.dark,
      backgroundColor: theme.palette.info.light,
      heroGradient: theme.palette.etaStatus.delivered.gradient,
      color: theme.palette.common.black,
      iconBoxColor: theme.palette.secondary[50],
      iconId: 'BeenhereOutlinedIcon',
      label: 'Delivered',
      type: 'delivered',
      variant: 'filled',
    },
  },
};

const getStyleProps = (props) => (props ? props : {});

const getStatusIcon = (statusKey, fontSize, icon, props) => {
  const { color, backgroundColor, label, variant } = props;
  const width = label ? '100%' : 'auto';

  return (
    <Stack direction="row" alignItems="center" spacing={1} width={width}>
      <Chip
        sx={{
          backgroundColor: backgroundColor,
          cursor: 'pointer',
          '& .MuiChip-icon': {
            margin: 0,
            padding: 0,
            color: color,
            fontSize: fontSize,
            ...getStyleProps(statusMap.propMap[statusKey]),
          },
        }}
        size="small"
        icon={icon}
        variant={variant}
      />
      {label && (
        <Typography variant="body4" noWrap>
          {label}
        </Typography>
      )}
    </Stack>
  );
};

const getIcon = (statusKey, fontSize, IconComponent, withLabel) => {
  const statusProps = statusMap.propMap[statusKey];
  const iconProps = {
    color: statusProps.color,
    backgroundColor: statusProps.backgroundColor,
    variant: statusProps.variant,
  };

  if (withLabel) {
    iconProps.label = statusProps.label;
  }

  return getStatusIcon(statusKey, fontSize, IconComponent, iconProps);
};

const getStatusBoxIcon = (statusKey) => {
  const statusItem = statusMap.propMap[statusKey];
  const { iconBoxColor } = statusItem;

  return (
    <Box
      sx={{
        width: '0.625rem',
        height: '0.625rem',
        backgroundColor: iconBoxColor,
      }}
    />
  );
};

const getTableCheckIcon = (fontSize) => getIcon('onSchedule', fontSize, <CheckIcon />, true);
const getCheckIcon = (fontSize) => getIcon('onSchedule', fontSize, <CheckIcon />);
const getTableWarningIcon = (fontSize) => getIcon('atRisk', fontSize, <WarningAmberIcon />, true);
const getWarningIcon = (fontSize) => getIcon('atRisk', fontSize, <WarningAmberIcon />);
const getTableCancelIcon = (fontSize) => getIcon('delayed', fontSize, <CloseIcon />, true);
const getCancelIcon = (fontSize) => getIcon('delayed', fontSize, <CloseIcon />);
const getTableDoNotDisturbIcon = (fontSize) => getIcon('etaUnavailable', fontSize, <RemoveCircleOutlineIcon />, true);
const getDoNotDisturbIcon = (fontSize) => getIcon('etaUnavailable', fontSize, <RemoveCircleOutlineIcon />);
const getTableTimerIcon = (fontSize) => getIcon('early', fontSize, <TimerOutlinedIcon />, true);
const getTimerIcon = (fontSize) => getIcon('early', fontSize, <TimerOutlinedIcon />);
const getTableDeliveredIcon = (fontSize) => getIcon('delivered', fontSize, <BeenhereOutlinedIcon />, true);
const getDeliveredIcon = (fontSize) => getIcon('delivered', fontSize, <BeenhereOutlinedIcon />);

// get icon by count type
export const countGetIcon = {
  onSchedule: getCheckIcon,
  atRisk: getWarningIcon,
  delayed: getCancelIcon,
  etaUnavailable: getDoNotDisturbIcon,
  early: getTimerIcon,
  delivered: getDeliveredIcon,
};

export const countGetTableIcon = {
  onSchedule: getTableCheckIcon,
  atRisk: getTableWarningIcon,
  delayed: getTableCancelIcon,
  etaUnavailable: getTableDoNotDisturbIcon,
  early: getTableTimerIcon,
  delivered: getTableDeliveredIcon,
};

// get icon by status type
export const typeGetIcon = {
  success: getCheckIcon,
  warning: getWarningIcon,
  early: getTimerIcon,
  error: getCancelIcon,
  etaUnavailable: getDoNotDisturbIcon,
  delivered: getDeliveredIcon,
};

export const typeGetBoxIcon = {
  success: getStatusBoxIcon('onSchedule'),
  warning: getStatusBoxIcon('atRisk'),
  early: getStatusBoxIcon('early'),
  error: getStatusBoxIcon('delayed'),
  etaUnavailable: getStatusBoxIcon('etaUnavailable'),
  delivered: getStatusBoxIcon('delivered'),
};

export const typeGetLabel = {
  success: 'Vehicle is anticipated to arrive on-time',
  warning: 'Vehicle may arrive after original delivery time',
  early: 'Vehicle is arriving ahead of schedule',
  error: 'Vehicle delivery is behind schedule',
  etaUnavailable: 'ETA is currently not available',
  delivered: '',
};

export const labels = statusMap.countTypes.map((countType) => statusMap.propMap[countType].label);
export const propMap = statusMap.propMap;
export const statusArray = countTypes.map((countType) => propMap[countType]);
export const colorArray = statusArray.map((status) => status.chartColor);

// sum array of objects, e.g. [{onSchedule: 50}, {onSchedule: 13}] -> [63]
export const reduceByCountType = (data, countType) => data.reduce((a, v) => a + (v[countType] || 0), 0);

export const tableStatusMap = {
  'on schedule': 'onSchedule',
  'at risk': 'atRisk',
  early: 'early',
  delayed: 'delayed',
  'eta unavailable': 'etaUnavailable',
  delivered: 'delivered',
};

export const swEtaStatusMap = {
  'On Schedule': 'onSchedule',
  'At Risk': 'atRisk',
  Early: 'early',
  Delayed: 'delayed',
  'ETA Unavailable': 'etaUnavailable',
  Delivered: 'delivered',
};

export const getStatusChipColor = (status) => {
  const key = tableStatusMap[status.toLowerCase()];
  return propMap[key]?.statusChipColor;
};

export const getStatusGradientColor = (status) => {
  const key = tableStatusMap[status.toLowerCase()];
  return propMap[key]?.heroGradient;
};
