import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import * as S from './TooltipWrapper.styles';

const TooltipWrapper = ({ children, tooltipText, isEnabled, ...rest }) => {
  if (!isEnabled) {
    return children;
  }

  return (
    <S.StyledTooltip
      istitle={+!!tooltipText}
      title={
        tooltipText && (
          <Typography whiteSpace="pre-line" variant="label2" component="span">
            {tooltipText}
          </Typography>
        )
      }
      arrow={!!tooltipText}
      {...rest}
    >
      {children}
    </S.StyledTooltip>
  );
};

TooltipWrapper.defaultProps = {
  isEnabled: true,
};

TooltipWrapper.propTypes = {
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isEnabled: PropTypes.bool,
  children: PropTypes.node,
};

export default TooltipWrapper;
