import React, { createContext, useCallback, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Slide, Snackbar } from '@mui/material';
import Notification from 'components/Notification/Notification';

const TRANSITION_MAP = {
  bottom: 'up',
  top: 'down',
};

export const ToastNotification = createContext({});

const NotificationProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState({
    title: 'Informational Notification',
    severity: 'info',
    horizontal: 'center',
    vertical: 'bottom',
  });

  const dispatchNotification = useCallback((notificationProps) => {
    setOpen(true);
    setNotification(notificationProps);
  }, []);

  const handleClose = () => setOpen(false);

  const contextValue = useMemo(
    () => ({
      dispatchNotification,
    }),
    [dispatchNotification],
  );

  const { horizontal, vertical, timeout, ...alertProps } = notification;

  return (
    <ToastNotification.Provider value={contextValue}>
      {children}
      <Snackbar
        open={open}
        anchorOrigin={{ horizontal: horizontal, vertical: vertical }}
        autoHideDuration={timeout || 5000}
        onClose={handleClose}
        TransitionComponent={Slide}
        TransitionProps={{ direction: TRANSITION_MAP[vertical] }}
      >
        <Paper elevation={2}>
          <Notification inline={false} handleClose={handleClose} {...alertProps} />
        </Paper>
      </Snackbar>
    </ToastNotification.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;

export const useNotificationContext = () => useContext(ToastNotification);
