import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import TooltipWrapper from 'components/TooltipWrapper/TooltipWrapper';

const StatusChip = ({ color, backgroundColor, icon, label, tooltipText, variant, sx }) => {
  const chipComponent = (
    <Chip
      variant={variant}
      size="small"
      label={label}
      icon={icon}
      sx={{
        ...sx,
        color,
        backgroundColor,
        gap: label ? 1 : 0,
        '.MuiChip-icon': { p: 0, m: 0, fontSize: '1rem', color: 'inherit' },
        cursor: 'pointer',
      }}
    />
  );

  if (tooltipText) {
    return <TooltipWrapper tooltipText={tooltipText}>{chipComponent}</TooltipWrapper>;
  }

  return chipComponent;
};

StatusChip.defaultProps = {
  label: null,
  icon: null,
  tooltipText: null,
  variant: 'filled',
};

StatusChip.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(['filled', 'outlined']),
  sx: PropTypes.object,
};

export default StatusChip;
