import { gql } from '@apollo/client';

export const GET_SERIES_OPTIONS = gql`
  query {
    PageFilter {
      brands {
        saleSeriesNames
        brandName
      }
    }
  }
`;

export const GET_SUPPRESSION_RULESET = gql`
  query GetSuppressionRuleset($rulesetId: String!) {
    GetSuppressionRuleset(rulesetId: $rulesetId) {
      ruleset {
        rulesetId
        version
        createdDate
        requestor
        userId
        division
        vehicleConditions {
          series
          modelYear
          modelCode
        }
        eventConditions {
          assignmentDestination
          assignmentOrigin
          buildDaysDiff
          fqaDaysDiff
          legDestination
          legOrigin
          predictedEventCode
          shipToRegion
          soldToRegion
          triggerEventCode
        }
      }
    }
  }
`;

export const GET_SUPPRESSION_RULES = gql`
  query GetAll($deletionFlag: Boolean) {
    GetSuppressionRules(deletionFlag: $deletionFlag) {
      count
      rulesets {
        rulesetId
        version
        reason
        startDate
        expiryDate
        exportFlag
        eventConditions {
          assignmentDestination
          assignmentOrigin
          buildDaysDiff
          fqaDaysDiff
          legDestination
          legOrigin
          predictedEventCode
          shipToRegion
          soldToRegion
          triggerEventCode
        }
        vehicleConditions {
          series
          modelYear
          modelCode
        }
        deletionFlag
        requestor
        suppressionFlag
        suppressionOrder
        createdDate
        deletionDate
      }
    }
  }
`;

export const DELETE_RULE = gql`
  mutation DeleteSuppressionRules($rulesetId: String!) {
    DeleteSuppressionRules(rulesetId: $rulesetId) {
      status
      message
    }
  }
`;

export const CREATE_RULE = gql`
  mutation CreateSuppressionRules($ruleset: SMRuleInput!) {
    CreateSuppressionRules(ruleset: $ruleset) {
      status
    }
  }
`;

export const UPDATE_RULE = gql`
  mutation UpdateSuppressionRules($rulesetId: String!, $ruleset: SMRuleUpdateInput!) {
    UpdateSuppressionRules(rulesetId: $rulesetId, ruleset: $ruleset) {
      status
    }
  }
`;
