import React from 'react';
import {
  AccessTimeFilledOutlined as AccessTimeFilledOutlinedIcon,
  BackHand as BackHandIcon,
  CelebrationOutlined as CelebrationIcon,
  DirectionsBoatOutlined as DirectionsBoatOutlinedIcon,
  DirectionsRailwayOutlined as DirectionsRailwayIcon,
  DoNotDisturbAltOutlined as DoNotDisturbAltOutlinedIcon,
  FactCheckOutlined as FactCheckIcon,
  LocalShippingOutlined as LocalShippingIcon,
  Pending as PendingIcon,
  PrecisionManufacturingOutlined as PrecisionManufacturingIcon,
  WarehouseOutlined as WarehouseIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Chip, Stack, Typography } from '@mui/material';
import NotRemovedIcon from 'icons/NotRemovedIcon/NotRemovedIcon';
import RemoveFromTrainingIcon from 'icons/RemoveFromTrainingIcon/RemoveFromTrainingIcon';
import SuppressEtaIcon from 'icons/SuppressEtaIcon/SuppressEtaIcon';
import StatusChip from 'components/StatusChip/StatusChip';
import ValueWithCopy from 'components/ValueWithCopy/ValueWithCopy';
import theme from '../theme';
import { general } from './';
import { countGetTableIcon, getStatusChipColor, tableStatusMap } from './Arrival/statusUtils';

dayjs.extend(utc);

export const getJourneyStepColor = ({ isActive, isComplete, swEtaStatus }) => {
  if (swEtaStatus?.toLowerCase() === 'eta unavailable') {
    return theme.palette.common.black;
  }
  if (isComplete) {
    return theme.palette.common.black;
  }
  return isActive ? getStatusChipColor(swEtaStatus?.toLowerCase()) : theme.palette.secondary[30];
};

// Format date

export const formatDate = (timestamp, format) => {
  return timestamp ? dayjs.utc(timestamp).format(format) : null;
};

// Journey event icon generation

export const generateJourneyEventIcon = ({ eventName, color, position, bottom }) => {
  const styles = { color, position, bottom };
  switch (eventName) {
    case 'Prod. Confirm':
      return <FactCheckIcon sx={styles} />;
    case 'Build':
      return <PrecisionManufacturingIcon sx={styles} />;
    case 'Processing':
    case 'VPC Arrival':
    case 'VPC Ready':
      return <WarehouseIcon sx={styles} />;
    case 'Rail Depart':
    case 'Rail Arrive':
      return <DirectionsRailwayIcon sx={styles} />;
    case 'On Truck for Delivery':
    case 'Truck Arrive':
    case 'Truck Depart':
      return <LocalShippingIcon sx={styles} />;
    case 'Arrival at Dealership':
      return <CelebrationIcon sx={styles} />;
    case 'Vessel Depart':
    case 'Vessel Arrive':
      return <DirectionsBoatOutlinedIcon sx={styles} />;
    default:
      return <DoNotDisturbAltOutlinedIcon sx={styles} />;
  }
};

export const generateStatusIcon = (params) => {
  if (params.value.toLowerCase() === 'n/a') {
    return null;
  }
  const key = tableStatusMap[params.value.toLowerCase()];
  return countGetTableIcon[key]({ fontSize: '1rem' });
};

export const generateStatusChip = (params) => {
  if (params.row.swEtaStatus.toLowerCase() === 'n/a') {
    return null;
  }

  const isEtaUnavailable = params.row.swEtaStatus === 'ETA Unavailable';
  const color = getStatusChipColor(params.row.swEtaStatus);

  return (
    <Chip
      sx={{
        color: 'common.white',
        backgroundColor: isEtaUnavailable ? theme.palette.secondary[50] : color,
        padding: '0.375rem 0.5rem',
        cursor: 'pointer',
      }}
      label={params.value}
      variant="contained"
    />
  );
};

const ADMIN_ACTION_MAP = {
  swSuppressionStatus: {
    date: 'swSuppressionLastUpdatedFmt',
    user: 'swSuppressionPreferredName',
    reason: 'swSuppressionComment',
  },
  swExclusionStatus: {
    date: 'swExclusionLastUpdatedFmt',
    user: 'swExclusionPreferredName',
    reason: 'swExclusionComment',
  },
};

const _getDetailText = (header, value) => (
  <Typography component="span" variant="label2">
    {
      <>
        <strong>{header}: </strong> {value}
      </>
    }
  </Typography>
);

export const withCopyIcon = ({ value, column }) => (
  <ValueWithCopy value={value} valueAlign={column.valueAlign}>
    <Typography component="span" variant="body4" noWrap>
      {value}
    </Typography>
  </ValueWithCopy>
);

export const withHoldIcon = ({ value }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <StatusChip color="common.white" backgroundColor="warning.main" icon={<BackHandIcon color="common.white" />} />
    <Typography component="span" variant="body4" noWrap>
      {value}
    </Typography>
  </Stack>
);

const withIcon = ({ value, column, row, icon, color, backgroundColor }) => {
  const details = ADMIN_ACTION_MAP[column?.field];

  const tooltipText = (row[details.date] || row[details.user] || row[details.reason]) && (
    <Stack>
      {row[details.date] && _getDetailText('Date', row[details.date])}
      {row[details.user] && _getDetailText('User', row[details.user])}
      {row[details.reason] && _getDetailText('Reason', row[details.reason])}
    </Stack>
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <StatusChip color={color} backgroundColor={backgroundColor} icon={icon} tooltipText={tooltipText} />
      <Typography component="span" variant="body4" noWrap>
        {value}
      </Typography>
    </Stack>
  );
};

export const withSuppressionIcon = (props) => {
  const iconProps = {
    'Suppression Lifted': {
      icon: <AccessTimeFilledOutlinedIcon />,
      color: 'success.10',
      backgroundColor: 'success.100',
    },
    Pending: { icon: <PendingIcon />, color: 'warning.10', backgroundColor: 'warning.100' },
    Suppressed: {
      icon: <SuppressEtaIcon fill={theme.palette.data.onErrorContainer} />,
      backgroundColor: 'data.errorContainer',
    },
  };

  const { icon, color, backgroundColor } = iconProps[props.value] || {};

  if (!icon) {
    return null;
  }

  return withIcon({ ...props, icon, color, backgroundColor });
};

export const withTrainingSetIcon = (props) => {
  const iconProps = {
    'Added Back': {
      icon: <NotRemovedIcon fill={theme.palette.success[10]} />,
      color: 'success.10',
      backgroundColor: 'success.100',
    },
    Removed: {
      icon: <RemoveFromTrainingIcon fill={theme.palette.data.onErrorContainer} />,
      backgroundColor: 'data.errorContainer',
    },
  };

  const { icon, color, backgroundColor } = iconProps[props.value] || {};

  if (!icon) {
    return null;
  }

  return withIcon({ ...props, icon, color, backgroundColor });
};

export const withFleetIndicator = ({ value }) => general.getTableChip(value, 'info.10', 'info.100');

export const withPhaseChip = (params) => (
  <StatusChip
    color="info.10"
    backgroundColor="info.100"
    label={params.value.toUpperCase()}
    tooltipText={params.row.lifecycleSubstatusDescription}
    sx={{ fontWeight: 600 }}
  />
);

export const generateBoolFleetValue = (params) => (params.value ? 'Fleet' : 'Non-fleet');
export const generateBoolOnHoldValue = (params) => (params.value ? 'On Hold' : 'Not on Hold');
export const generateBoolOnQcHoldValue = (params) => (params.value ? 'On QC Hold' : 'Not on QC Hold');
export const generateBoolOnDamageHoldValue = (params) => (params.value ? 'On Damage Hold' : 'Not on Damage Hold');

export const numAddLeadingZeros = (params) => {
  if (!params?.value) {
    return 'N/A';
  }
  params.value = params.value.toString();
  return params.value.padStart(5, '0');
};

export const localize = (params) => {
  if (!params.value) {
    return 'N/A';
  }

  return params.value.toLocaleString();
};

export const localizedPercentage = (params) => {
  if (!params.value) {
    return 'N/A';
  }

  return params.value.toFixed(2).toLocaleString() + ' %';
};
