import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';

export const StyledTooltip = styled((props) => <Tooltip classes={{ popper: props.className }} {...props} />)`
  .MuiTooltip-tooltip {
    background: ${(props) => props.theme.palette.background.dark};
    padding: ${(props) => (props.istitle ? '0.5rem 1rem' : 0)};
    max-width: none;
  }

  .MuiTooltip-arrow {
    color: ${(props) => props.theme.palette.background.dark};
  }
`;
