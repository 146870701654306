import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFeaturesContext } from 'contexts/Features.context';
import { useRolesContext } from './Roles.context';

const DIST_TO_VIEW_MAP = {
  91041: ['nationalComparison', 'distributorSummary'],
  92092: ['distributorSummary'],
  92121: ['distributorSummary'],
};

const _getInitialDistributor = (distributorMapping, options, region) => {
  if (!options.distributorCode || !region || !distributorMapping) {
    return options;
  }

  const distributor = distributorMapping.find((entry) => entry.regions.includes(region));

  return { ...options, distributorCode: distributor ? [distributor.distributorCode] : [''] };
};

const _getAllowedDistributor = (distributorMapping, options, region) => {
  if (!options.distributorCode || !region || !distributorMapping) {
    return options;
  }

  const result = options.distributorCode.filter((distributorCode) => {
    const distributor = distributorMapping.find((item) => item.distributorCode === distributorCode);
    return distributor && distributor.regions.includes(region);
  });

  return { ...options, distributorCode: result };
};

export const ArrivalContext = createContext();

const _getPossibleViews = (distributorCodes) => {
  const allowedViews = [];

  if (distributorCodes?.length) {
    Object.entries(DIST_TO_VIEW_MAP).forEach(([code, views]) => {
      if (distributorCodes.includes(code)) {
        allowedViews.push(...views);
      }
    });
  }

  return [...new Set(allowedViews)];
};

const _getAllowedViews = (sideNav, distributorCodes) =>
  sideNav.map((navItem) => ({
    ...navItem,
    views: navItem.views.filter((item) => _getPossibleViews(distributorCodes).includes(item.view)),
  }));

const ArrivalProvider = ({ children }) => {
  const { features } = useFeaturesContext();
  const { region, distributorCodes } = useRolesContext();
  const [selectedView, setSelectedView] = useState(_getPossibleViews(distributorCodes)[0]);
  const [openMarker, setOpenMarker] = useState(null);

  const distributorMapping = features?.distributorMapping;

  const updateSelectedView = useCallback((value) => setSelectedView(value), []);
  const updateOpenMarker = useCallback((value) => setOpenMarker(value), []);
  const getAllowedViews = useCallback((sideNav) => _getAllowedViews(sideNav, distributorCodes), [distributorCodes]);
  const getAllowedDistributor = useCallback(
    (options) => _getAllowedDistributor(distributorMapping, options, region),
    [distributorMapping, region],
  );
  const getInitialDistributor = useCallback(
    (options) => _getInitialDistributor(distributorMapping, options, region),
    [distributorMapping, region],
  );

  const contextValue = useMemo(
    () => ({
      selectedView,
      openMarker,
      updateSelectedView,
      updateOpenMarker,
      getAllowedViews,
      getAllowedDistributor,
      getInitialDistributor,
    }),
    [selectedView, openMarker],
  );

  return <ArrivalContext.Provider value={contextValue}>{children}</ArrivalContext.Provider>;
};

ArrivalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ArrivalProvider;

export const useArrivalContext = () => useContext(ArrivalContext);
