import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import { useAuth } from 'react-oidc-context';
import { useFeaturesContext } from 'contexts/Features.context';
import { useRolesContext } from 'contexts/Roles.context';
import SuppressionManagerProvider from 'contexts/SuppressionManager.context';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

const Arrival = lazy(() => import('./pages/Arrival/Arrival'));
const Dwell = lazy(() => import('./pages/Dwell/Dwell'));
const ErrorPage = lazy(() => import('./pages/ErrorPage/ErrorPage'));
const HistoricalPerformance = lazy(() => import('./pages/HistoricalPerformance/HistoricalPerformance'));
const ModelPerformance = lazy(() => import('./pages/ModelPerformance/ModelPerformance'));
const SuppressionManager = lazy(() => import('./pages/SuppressionManager/SuppressionManager'));

const ProtectedRoute = ({ allowedPermissions }) => {
  const { permissionsExist } = useRolesContext();

  return !permissionsExist(allowedPermissions) ? <ErrorPage statusCode={401} /> : <Outlet />;
};

ProtectedRoute.propTypes = {
  allowedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Router = () => {
  const auth = useAuth();
  const { features } = useFeaturesContext();

  switch (auth.activeNavigator) {
    case 'signinSilent':
    case 'signinRedirect':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner fullScreen />}>
        <Routes>
          <Route path="/" element={<ProtectedRoute allowedPermissions={['admin', 'general']} />}>
            <Route index exact element={<Arrival pageLens="DEALER_ETA" />} />
            {features.customerEtaEnabled && (
              <Route path="/customer-eta" element={<ProtectedRoute allowedPermissions={['admin', 'general']} />}>
                <Route index exact element={<Arrival pageLens="CUSTOMER_ETA" />} />
              </Route>
            )}
            {features.modelPerformanceEnabled && (
              <Route path="/model" element={<ProtectedRoute allowedPermissions={['admin']} />}>
                <Route index exact element={<ModelPerformance />} />
              </Route>
            )}
            {features.historicalPerformanceEnabled && (
              <Route path="/historical" element={<ProtectedRoute allowedPermissions={['admin']} />}>
                <Route index element={<HistoricalPerformance report="etaaccuracy" />} />
              </Route>
            )}
            {features.dwellEnabled && (
              <Route path="/dwell" element={<ProtectedRoute allowedPermissions={['admin']} />}>
                <Route index element={<Dwell />} />
              </Route>
            )}
            {features.suppressionManagerEnabled && (
              <Route path="/suppression-manager" element={<ProtectedRoute allowedPermissions={['admin']} />}>
                <Route
                  index
                  element={
                    <SuppressionManagerProvider>
                      <SuppressionManager />
                    </SuppressionManagerProvider>
                  }
                />
              </Route>
            )}
          </Route>
          <Route path="/authorization" element={<ErrorPage statusCode={401} />} />
          <Route path="*" element={<ErrorPage statusCode={404} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default withErrorBoundary(Router, {
  fallback: <ErrorPage statusCode={500} hideNavBar />,
});
