import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const RemoveFromTrainingIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" enableBackground="new 0 0 24 24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M9.5 19.6C6.3 18.5 4 15.5 4 12c0-1.8.6-3.5 1.7-4.9l4.3 4.3c.4-.6.9-1 1.6-1.2L7.1 5.7C8.5 4.6 10.2 4 12 4c3.7 0 6.8 2.6 7.7 6h1.5c.2 0 .4 0 .5.1C20.9 5.5 16.9 2 12 2 6.5 2 2 6.5 2 12c0 4.7 3.2 8.6 7.5 9.7v-2.1z" />
    <path
      d="M12.7 23c-.7 0-1.2-.5-1.2-1.2v-8.6c0-.7.5-1.2 1.2-1.2h8.6c.7 0 1.2.5 1.2 1.2v8.6c0 .7-.5 1.2-1.2 1.2h-8.6zm1.6-3.2-.9-.9 2.7-2.7 1.2 1.2 2.2-2.2.9.9-3.1 3.1-1.1-1.2-1.9 1.8z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>,
  'RemoveFromTraining',
);

export default RemoveFromTrainingIcon;
