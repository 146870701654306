import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const SuppressEtaIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" enableBackground="new 0 0 24 24" viewBox="0 0 24 24">
    <path d="M9.8 19.7c-3.3-1-5.8-4-5.8-7.7 0-1.8.6-3.5 1.7-4.9l5.6 5.6c.4-.5.9-1 1.5-1.3L7.1 5.7C8.5 4.6 10.2 4 12 4c3.9 0 7.2 2.8 7.9 6.6.8.3 1.5.8 2.1 1.3C22 6.4 17.5 2 12 2S2 6.5 2 12c0 5.2 3.9 9.4 9 9.9-.5-.6-.9-1.4-1.2-2.2z" />
    <path d="M17 12c-3 0-5.5 2.5-5.5 5.5S14 23 17 23s5.5-2.5 5.5-5.5S20 12 17 12zm2.8 7.1-.2.4-.2.4-2.9-1.8v-3.4h1v2.8l2.3 1.6z" />
    <path d="M17 12c-3 0-5.5 2.5-5.5 5.5S14 23 17 23s5.5-2.5 5.5-5.5S20 12 17 12zm2.8 7.1-.2.4-.2.4-2.9-1.8v-3.4h1v2.8l2.3 1.6z" />
  </svg>,
  'SuppressEta',
);

export default SuppressEtaIcon;
