import styled from '@emotion/styled';
import { Stack as MuiStack, Box as MuiBox } from '@mui/material';

export const Stack = styled(MuiStack)`
  width: 100%;
  height: 100%;
  align-items: center;
  color: ${({ hovered, theme }) => (hovered ? theme.palette.info.main : theme.palette.common.black)};

  .MuiSvgIcon-ContentCopy {
    visibility: hidden;
    cursor: pointer;
  }

  &:hover {
    .MuiSvgIcon-ContentCopy {
      visibility: visible;
    }
  }
`;

export const Box = styled(MuiBox)`
  display: flex;
  align-items: center;
`;
